"use client";

import React, { createContext, ReactNode, useState } from "react";
import { FlashContextValue, FlashMessageValue } from "@/TS/Interfaces/global_interfaces";

/**
 * @returns Object - {flashContextValue, setFlashContextValue, useFlashContext}
 * @description value return that {
 *     type: string,
 *     message: string,
 *     position?: string
 * }
 * when u want to use the flash context do this :
 * SetValue({
 *     type: ""
 * })
 */
export const flashContext = createContext<FlashContextValue>(null);
function FlashContextProvider({
  children
}): ReactNode {
  const [flashContextValue, setFlashContextValue] = useState<FlashMessageValue>({
    type: "",
    message: ""
  });
  const applyFlashContext = (value: FlashMessageValue, timeoutMs: number) => {
    let timeoutId;
    setFlashContextValue(value);
    if (value) {
      timeoutId = setTimeout(() => {
        setFlashContextValue({
          type: "",
          message: ""
        });
      }, timeoutMs ?? 5000);
    }
  };
  const vals: FlashContextValue = {
    flashContextValue,
    setFlashContextValue,
    applyFlashContext
  };
  return <flashContext.Provider value={vals} data-sentry-element="unknown" data-sentry-component="FlashContextProvider" data-sentry-source-file="flash-context.tsx">{children}</flashContext.Provider>;
}
export default FlashContextProvider;
import React from "react";
import { twMerge } from "tailwind-merge";
import { FormError } from "@/TS/Interfaces/global_interfaces";
function ErrorsInput({
  changed,
  className,
  error
}: {
  changed: boolean;
  className?: string;
  error?: FormError;
}) {
  if (error?.err && !changed) {
    if (!Array.isArray(error)) {
      return <p className={twMerge("text-primary text-sm mt-1", className)}>
          {error.message}
        </p>;
    } else {
      return error.map(res => {
        return <p key={res.message} className={twMerge("text-primary text-sm mt-1", className)}>
            {res.message}
          </p>;
      });
    }
  } else {
    return;
  }
}
export default ErrorsInput;
import { updateAvatar } from "@/app/api/dynabuy-back/api-profile";

export const fetchAdresses = async (value, setStreet) => {
  try {
    const url = new URL("https://api-adresse.data.gouv.fr/search/");
    const params = new URLSearchParams(url.search);
    params.set("q", value);
    const adresses = await fetch(url + "?" + params.toString());
    setStreet(await adresses.json().then((res) => res.features));
  } catch (err) {}
};

export const handleFile = async (file, inputRef, url, setUrl) => {
  inputRef.current.value = null;
  if (url == "") {
    const newUrl = URL.createObjectURL(file);
    setUrl(newUrl);
  }
  if (file) {
    const newUrl = URL.createObjectURL(file);
    if (url !== newUrl) {
      setUrl(newUrl);
    }
  }
  const formData = new FormData();
  formData.append("file", file);
  await updateAvatar(formData);
};

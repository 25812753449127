import React, { useContext, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { scrollToTop } from "@/app/(main)/(club)/mes-clubs/[id]/leads/utils";
import ErrorsInput from "@/components/global/inputs/errors-input";
import Spinner from "@/components/global/spinner";
import Icon from "@/components/icon";
import { sidebarContext } from "@/context/sidebar-context";
import { TextInputProps } from "@/TS/Interfaces/global_interfaces";
import { fetchAdresses } from "@/utils/user";

//==========*=*=============
// TODO en cours de refacto
//
//
//
//
//==========*=*=============

/**
 * A custom input who work with useForm by passing register={register} and a name with name={name}
 *
 * @remarks
 * [useForm docs](https://react-hook-form.com/docs/useform)
 *
 * @example
 * ```tsx
 * <TextInput
 *  register={register}
 *  name={"firstName"}
 *  type={"text"}
 *  label={"Prénom"}
 *  subLabel={"sublabel"}
 *  placeholder={"Prénom"}
 *  disabled={true}
 *  required={false}
 * ></TextInput>
 * ```
 */
function TextInput({
  type = "text",
  name,
  error,
  disabledClassName,
  disabled = false,
  required = false,
  loading,
  needed = true,
  register,
  streets = false,
  autoCorrect,
  autoCapitalize,
  handleStreets,
  radioName = "default",
  className,
  labelClassName,
  options,
  nextLabelClassName,
  nextLabel,
  valueString,
  placeholder,
  label,
  value,
  radioClassName,
  autocomplete = "on",
  checked,
  subLabel,
  id,
  containerClassName,
  requireClassname,
  inputClassname,
  errorsClassName,
  ...rest
}: TextInputProps) {
  const [val, setVal] = useState(value);
  const [street, setStreet] = useState();
  const [isStreet, setIsStreet] = useState(false);
  const [changed, setChanged] = useState(false);
  const inputRef = useRef<null | HTMLInputElement>(null);
  const {
    panelRef
  } = useContext(sidebarContext);
  const [passwordShow, setPasswordShow] = useState(false);
  let inputType = type;
  const togglePasswordShow = () => {
    setPasswordShow(!passwordShow);
  };
  if (passwordShow && type === "password") {
    inputType = "text";
  }
  const handleChange = value => {
    if (handleStreets) {
      fetchAdresses(value, setStreet);
    }
    setChanged(true);
  };
  useEffect(() => {
    setChanged(false);
  }, [error]);
  useEffect(() => {
    if (Boolean(error?.err) && inputRef.current) {
      scrollToTop(inputRef.current.offsetTop, panelRef, inputRef);
    }
  }, [error]);
  const handleEnter = val => {
    const {
      label,
      name,
      street,
      city,
      postcode
    } = val;
    handleStreets(city, postcode, street);
  };
  const handleFocus = e => {
    if (setIsStreet) {
      setIsStreet(true);
    }
  };
  const handleBlur = () => {
    if (setIsStreet) {
      setIsStreet(false);
    }
  };
  useEffect(() => {
    handleChange(value);
    setChanged(false);
  }, []);
  useEffect(() => {
    setVal(value);
  }, [value]);
  if (type === "radio") {
    return <div className={"flex gap-2 mt-1 items-center justify-start " + className}>
        <input value={value} defaultChecked={checked} name={radioName} type="radio" id={label} {...register(name, {
        ...options
      })} {...rest} className={twMerge("text-black bg-transparent cursor-pointer appearance-none w-4 h-4 rounded-full bg-white ring-2 ring-black flex items-center transition-all justify-center before:scale-0 before:w-4 checked:before:scale-[60%] before:rounded-full before:h-4 before:bg-black before:relative", radioClassName)} />
        <label className={"cursor-pointer"} htmlFor={label}>
          {label}
        </label>
      </div>;
  }
  if (!disabled) {
    const {
      ref
    } = register(name);
    return <div className={twMerge("w-full relative", containerClassName)}>
        <label className={"text-md " + labelClassName ?? ""} htmlFor={id}>
          {label}{" "}
          {needed && <span className={twMerge("text-primary ", requireClassname)}>
              *
            </span>}{" "}
          <label className={nextLabelClassName}>{nextLabel}</label>
        </label>
        <div className={`flex mt-1 border items-center h-[48px] rounded-md border-dark-grey justify-center relative ${error?.err && !changed && "border-primary  !bg-danger3 "} ${className}`}>
          <div className="relative flex items-center bg-transparent justify-center w-full rounded-md">
            <input defaultValue={value} {...register(name, {
            onChange: e => handleChange(e.target.value),
            onBlur: handleBlur,
            ...options
          } ?? undefined)} onFocus={handleFocus} autoCorrect={autoCorrect} autoCapitalize={autoCapitalize} name={name} className={twMerge("w-full bg-transparent  autofill:bg-transparent!important placeholder:italic  rounded-md  pl-4 pr-1", error?.err && !changed && "text-primary bg-danger3 border-primary", inputClassname)} type={inputType} placeholder={placeholder} autoComplete={autocomplete} id={id} {...rest} ref={e => {
            ref(e);
            inputRef.current = e;
          }} />
            {loading && <Spinner></Spinner>}
            {error?.err && !changed && <Icon icon={"cross"} size={25} className={"absolute right-2 text-primary"} />}
            {error?.err === false && !changed && <Icon icon={"check-simple"} size={25} className={"absolute right-2 text-success2"} />}
          </div>
          {type === "password" && <div className={`${type === "password" ? "w-12" : "md:w-12"} flex justify-center items-center hover:cursor-pointer select-none`}>
              <Icon size={30} icon={passwordShow ? "eye-hide" : "eye-show"} onClick={() => {
            togglePasswordShow();
          }} />
            </div>}
          {type === "selectDate" && <div className={`md:w-12 flex justify-center items-center select-none`}>
              <Icon size={24} icon={"agenda"} />
            </div>}
          {/*{isCorrect && (*/}
          {/*  <Icon*/}
          {/*    className={*/}
          {/*      !subLabel*/}
          {/*        ? "relative mr-4 text-success2 min-w-[30px]"*/}
          {/*        : "relative mr-4 text-success2 min-w-[30px]"*/}
          {/*    }*/}
          {/*    icon={"check-simple"}*/}
          {/*  />*/}
          {/*)}*/}
          {subLabel && !required && <p className={"bg-primary h-full cursor-text rounded-r-md flex items-center justify-center ring-1 text-lg font-bold ring-primary text-white px-4"}>
              {subLabel}
            </p>}
        </div>
        <ErrorsInput changed={changed} error={error} className={errorsClassName} />
        {streets && <div style={isStreet ? {
        transform: "scale(1) translateY(100%)"
      } : {
        transform: "scale(0) translateY(100%)"
      }} className="flex-col overflow-hidden rounded-md mt-2 translate-y-full absolute z-10 -bottom-2 bg-light-grey">
            {Array.isArray(street) && street !== undefined && street.length !== 0 && street.map(res => {
          return <p key={res.properties.label} onMouseDown={() => handleEnter(res.properties)} className={"cursor-pointer p-2 border-b-[1px] pt-4 border-grey hover:bg-grey"}>
                    {res.properties.label}
                  </p>;
        })}
          </div>}
      </div>;
  } else {
    return <div className={"w-full  relative"}>
        <label htmlFor="" className={"text-md"}>
          {label} {needed && <span className={"text-primary"}>*</span>}
        </label>
        <p className={twMerge("p-2.5 text-dark-grey bg-light-grey rounded-md", disabledClassName)}>
          {val}
        </p>
      </div>;
  }
}
export default React.memo(TextInput);
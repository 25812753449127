"use client";

import { useState, useContext } from "react";
import { flashContext } from "@/context/flash-context";
import { sidebarContext } from "@/context/sidebar-context";
import { ApiWriteResponse, UseSubmitOptions, UseSubmitReturn, ViolationApiResponse } from "@/TS/Interfaces/global_interfaces";
import { removeEmptyFields } from "@/utils/global-utils";
function UseSubmit<TReturnedObject, TData, TResponse extends ApiWriteResponse<TReturnedObject>>(fetchApi: (data: TData, ...args: Array<any>) => Promise<TResponse>, options?: UseSubmitOptions): UseSubmitReturn<TData> {
  const [errors, setErrors] = useState<undefined | any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    applyFlashContext
  } = useContext(flashContext);
  const {
    setVal: setSidebarValue
  } = useContext(sidebarContext);
  async function onSubmit(data: TData, ...args: any[]): Promise<void> {
    removeEmptyFields(data);
    setErrors(undefined);
    setLoading(true);
    try {
      const res: TResponse = await fetchApi(data, ...args);
      setLoading(false);
      handleResponse(res, options);
    } catch (error) {
      // Generic Errors
      setLoading(false);
      setErrors([{
        message: (error as Error).message
      }]);
    }
  }
  function handleResponse(res: TResponse, options) {
    if (!res.error) {
      if (typeof options.callbackMessage === "object" && "message" in res) {
        options.callbackMessage.message = (res as any).message;
      }
      if (options.sidebarJsx !== undefined) {
        setSidebarValue(options.sidebarJsx);
      }
      if (typeof options.callbackMessage === "object" && (options.callbackMessage.type === "success" || options.callbackMessage.type === "success2")) {
        applyFlashContext(options.callbackMessage, options.callbackMessageTimeoutMs);
      }
      if (options.callback) {
        options.callback();
      }
    } else {
      handleErrors(res);
    }
  }
  const handleErrors = (res: TResponse): void => {
    if (typeof options.callbackMessage === "object" && options.callbackMessage.type === "primary") {
      applyFlashContext(options.callbackMessage, options.callbackMessageTimeoutMs);
    } else if ("violations" in res && res.violations) {
      setErrors(res.violations);

      //look for violation with empty propertyPath (to display error)
      const violationWithoutPath: ViolationApiResponse = res.violations.find((element: ViolationApiResponse) => "" === element.propertyPath);
      if (violationWithoutPath) {
        applyFlashContext({
          message: violationWithoutPath.message || "An error occurred",
          type: "primary",
          position: options.errorFlashPath
        });
      }
    } else {
      applyFlashContext({
        message: res.message || "An error occurred",
        type: "primary",
        position: options.errorFlashPath
      });
    }
  };
  return {
    errors,
    setErrors,
    loading,
    setLoading,
    onSubmit,
    setVal: setSidebarValue
  };
}
export default UseSubmit;
"use client";

import React, { useContext } from "react";
import Flash from "@/components/flash";
import { flashContext } from "@/context/flash-context";
function ClientFlash({
  path,
  className
}: {
  path?: string;
  className?: string;
}) {
  const {
    flashContextValue
  } = useContext(flashContext);
  return <>
      {flashContextValue.message && (flashContextValue.position === path || flashContextValue.position === "global") && <Flash type={flashContextValue.type} className={className}>
            {flashContextValue.message}
          </Flash>}
    </>;
}
export default ClientFlash;